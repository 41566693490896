<template>
  <b10-base>
    <b10-toolbar
      :title="title"
    >
      <v-btn
        slot="right"
        icon
        @click.stop="clickSubmit($refs.form)"
      >
        <v-icon>{{ $vuetify.icons.values.check }}</v-icon>
      </v-btn>
    </b10-toolbar>
    <b10-page-content
      :padding="3"
    >
      <ValidationObserver
        ref="form"
      >
        <lparte-novedad-form
          :geolocalizacion="geolocalizacion"
          :punto-obligatorio="puestoServicio && puestoServicio.novedad_punto_obligatorio"
          :idpuesto-servicio="puestoServicio && puestoServicio.idpuesto_servicio"
          @change-values="changeFormData"
        />
      </ValidationObserver>
    </b10-page-content>
  </b10-base>
</template>

<script>
import { formPageMixin } from '@/mixins/formPageMixin'
import LparteNovedadForm from '../components/LparteNovedadForm'
import Data from './LparteNovedadAddData'
import { TDATO_NOVEDAD } from '@/utils/consts'
import { captureGeolocation } from '@/utils/maps'
import { toDate } from '@/utils/date'

export default {
  components: {
    LparteNovedadForm
  },
  mixins: [formPageMixin],
  data () {
    return {
      geolocalizacion: {},
      puestoServicio: {},
    }
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      this.title = 'Añadir novedad'
      this.$set(this, 'puestoServicio', await Data.selectPuestoServicio(this, this.routeParams.idparte_novedad))
      this.geolocalizacion = await captureGeolocation(this)
    },
    changeFormData (formData) {
      this.formData = formData
    },
    async submitForm () {
      // caso especial de campos fecha -> convertir a Date si se modificaron
      for (const dato of this.formData.datosNovedad) {
        if (
          dato.tdato_novedad_tipo_valor === TDATO_NOVEDAD.tipos.fecha &&
          dato.valor &&
          typeof(dato.valor) === 'string'
        ) {
          dato.valor = toDate(dato.valor)
        }
      }
      const inserted = await Data.insert(this, this.formData, this.routeParams.idparte_novedad)
      this.$appRouter.replace({
        name: 'partesnovedad__lparte-novedad-view',
        params: {
          idlparte_novedad: inserted.idlparte_novedad,
        },
      })
    },
  },
}
</script>
