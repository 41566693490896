import { APIFilter } from '@/utils/api'

export default {
  async selectPuestoServicio(Vue, idparteNovedad) {
    const parteApiFilter = new APIFilter()
    parteApiFilter.addExact('idparte_novedad', idparteNovedad)
    const respParte = await Vue.$api.call('parteNovedad.select', { filter: parteApiFilter })
    const parte = respParte.data.result.dataset[0]

    const puestoApiFilter = new APIFilter()
    puestoApiFilter.addExact('idpuesto_servicio', parte.idpuesto_servicio)
    const resp = await Vue.$api.call('puestoServicio.select', { filter: puestoApiFilter })
    return resp.data.result.dataset[0]
  },
  async insert (Vue, formData, idparteNovedad) {
    const resp = await Vue.$api.call(
      'lparteNovedad.insert',
      {
        values: {
          idparte_novedad: idparteNovedad,
          fnovedad: formData.fnovedad,
          idtnovedad: formData.tnovedad.idtnovedad,
          idfuerza_seguridad: formData.idfuerza_seguridad,
          descripcion: formData.descripcion,
          informe_cliente: formData.informe_cliente,
          estado: formData.estado,
          latitud: formData.latitud,
          longitud: formData.longitud,
          idpunto: formData.idpunto,
        },
      }
    )
    const lparteNovedad = resp.data.result.dataset[0]
    // datos dinámicos -> DATO_LPARTE_NOVEDAD
    for (const dato of formData.datosNovedad) {
      if (dato.valor) {
        await Vue.$api.call(
          'datoLparteNovedad.insert',
          {
            values: {
              idtdato_novedad: dato.idtdato_novedad,
              idlparte_novedad: lparteNovedad.idlparte_novedad,
              valor: dato.valor,
              estado: 1,
            },
          }
        )
      }
    }
    return lparteNovedad
  },
}
